export default {
  app: {
    orgCode: "Топтун/бренд номери",
    orgCodePlaceholder: "Топтун/бренд номерин киргизиңиз",
    username: "эсеп",
    usernamePlaceholder: "Сураныч, эсеп номерин киргизиңиз",
    password: "купуя сөз",
    passwordPlaceholder: "Сырсөздү киргизиңиз",
    captcha: "Текшерүү коду",
    captchaPlaceholder: "Текшерүү кодун киргизиңиз",
    captchaExpired: "Текшерүү кодун жаңыртыңыз.",
    login: "Кириңиз",
    remember: "Кирүү маалыматын унутпаңыз",
    switchPC: "Фонго которуу",
    setupPassword: "Сырсөз коюу",
    newPassword: "жаңы сыр сөз",
    newPasswordPlaceholder: "Сураныч, жаңы сырсөздү киргизиңиз",
    retypePassword: "Жаңы сырсөздү кайра киргизиңиз",
    retypePasswordPlaceholder: "Сураныч, жаңы сырсөздү кайра киргизиңиз",
    setupPasswordRule: "Сырсөз 6-32 белгиден турат жана эки же андан көп сандардын, тамгалардын, символдордун ж.б.",
    setupPasswordRuleRefer: "Сырсөз 6-32 белгиден турат жана эки же андан көп сандардын, тамгалардын, символдордун ж.б. Сураныч, кайра кириңиз.",
    setupPasswordOk: "Албетте",
    setupPasswordDiffer: "Эки жолу киргизилген сырсөздөр дал келбейт, кайра киргизиңиз.",
    err_def: "Кирүү ишке ашкан жок, баракты жаңыртып, кайра аракет кылыңыз.",
    err_2000: "Текшерүү коду туура эмес киргизилди.",
    err_2010: "Кирүү маалыматы туура эмес киргизилген.",
    err_4001: "Бул эсеп эч кандай функцияларга уруксат берилген эмес.",
    err_4011: "Дүкөн өчүрүлгөн жана кирүү мүмкүн эмес.",
    err_4012: "Франчайзи өчүрүлгөн жана ага кирүү мүмкүн эмес.",
    err_4013: "Бренд өчүрүлгөн жана ага кирүү мүмкүн эмес.",
    err_4014: "Топ өчүрүлгөн жана ага кирүү мүмкүн эмес.",
    err_4015: "Аймак өчүрүлгөн жана кирүү мүмкүн эмес.",
    err_5001: "Сырсөз коюлбай калды, кайра аракет кылыңыз.",
  },
}