export default {
  app: {
    orgCode: "ໝາຍເລກກຸ່ມ/ຍີ່ຫໍ້",
    orgCodePlaceholder: "ກະລຸນາໃສ່ໝາຍເລກກຸ່ມ/ຍີ່ຫໍ້",
    username: "ບັນຊີ",
    usernamePlaceholder: "ກະລຸນາໃສ່ຊື່ຜູ້ໃຊ້",
    password: "ລະຫັດຜ່ານ",
    passwordPlaceholder: "ກະລຸນາໃສ່ລະຫັດຜ່ານ",
    captcha: "ລະຫັດຢືນຢັນ",
    captchaPlaceholder: "ກະລຸນາໃສ່ລະຫັດຢືນຢັນ",
    captchaExpired: "ກະລຸນາໂຫຼດລະຫັດຢືນຢັນຄືນໃໝ່.",
    login: "ເຂົ້າ​ສູ່​ລະ​ບົບ",
    remember: "ຈື່ຂໍ້ມູນການເຂົ້າສູ່ລະບົບ",
    switchPC: "ປ່ຽນເປັນພື້ນຫຼັງ",
    setupPassword: "ຕັ້ງລະຫັດຜ່ານ",
    newPassword: "ລະຫັດຜ່ານໃໝ່",
    newPasswordPlaceholder: "ກະລຸນາໃສ່ລະຫັດຜ່ານໃໝ່",
    retypePassword: "ໃສ່ລະຫັດຜ່ານໃໝ່ອີກຄັ້ງ",
    retypePasswordPlaceholder: "ກະລຸນາໃສ່ລະຫັດຜ່ານໃໝ່ອີກຄັ້ງ",
    setupPasswordRule: "ລະຫັດຜ່ານແມ່ນ 6-32 ຕົວອັກສອນແລະຕ້ອງມີສອງຫຼືຫຼາຍກວ່າການປະສົມຂອງຕົວເລກ, ຕົວອັກສອນ, ສັນຍາລັກ, ແລະອື່ນໆ.",
    setupPasswordRuleRefer: "ລະຫັດຜ່ານແມ່ນ 6-32 ຕົວອັກສອນແລະຕ້ອງມີສອງຫຼືຫຼາຍກວ່າການປະສົມຂອງຕົວເລກ, ຕົວອັກສອນ, ສັນຍາລັກ, ແລະອື່ນໆ. ກະລຸນາໃສ່ອີກເທື່ອຫນຶ່ງ.",
    setupPasswordOk: "ແນ່ນອນ",
    setupPasswordDiffer: "ລະຫັດຜ່ານທີ່ໃສ່ສອງເທື່ອບໍ່ສອດຄ່ອງກັນ, ກະລຸນາໃສ່ໃໝ່.",
    err_def: "ເຂົ້າ​ສູ່​ລະ​ບົບ​ບໍ່​ສໍາ​ເລັດ​, ກະ​ລຸ​ນາ​ໂຫຼດ​ຫນ້າ​ຄືນ​ໃຫມ່​ແລະ​ພະ​ຍາ​ຍາມ​ອີກ​ເທື່ອ​ຫນຶ່ງ​.",
    err_2000: "ລະຫັດຢືນຢັນບໍ່ຖືກຕ້ອງ.",
    err_2010: "ຂໍ້ມູນການເຂົ້າສູ່ລະບົບບໍ່ຖືກຕ້ອງ.",
    err_4001: "ບັນຊີນີ້ບໍ່ໄດ້ຮັບອະນຸຍາດໃຫ້ເຮັດໜ້າທີ່ໃດໆ.",
    err_4011: "ຮ້ານຄ້າຖືກປິດການນຳໃຊ້ ແລະບໍ່ສາມາດເຂົ້າສູ່ລະບົບໄດ້.",
    err_4012: "ແຟນໄຊໄດ້ຖືກປິດການນຳໃຊ້ ແລະບໍ່ສາມາດເຂົ້າສູ່ລະບົບໄດ້.",
    err_4013: "ຍີ່ຫໍ້ຖືກປິດການນຳໃຊ້ ແລະບໍ່ສາມາດເຂົ້າສູ່ລະບົບໄດ້.",
    err_4014: "ກຸ່ມດັ່ງກ່າວຖືກປິດການນຳໃຊ້ ແລະບໍ່ສາມາດເຂົ້າສູ່ລະບົບໄດ້.",
    err_4015: "ການຂດຂໍ້ມູນຖືກຕ້ອງ ແລະ ບໍ່ສາມາດເຂົ້າໃຊ້",
    err_5001: "ຕັ້ງລະຫັດຜ່ານບໍ່ສຳເລັດ, ກະລຸນາລອງໃໝ່ອີກຄັ້ງ.",
  },
}