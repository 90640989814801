export default {
  app: {
    orgCode: "集团/品牌编号",
    orgCodePlaceholder: "请输入集团/品牌编号",
    username: "账号",
    usernamePlaceholder: "请输入账号",
    password: "密码",
    passwordPlaceholder: "请输入密码",
    captcha: "验证码",
    captchaPlaceholder: "请输入验证码",
    captchaExpired: "请刷新验证码。",
    login: "登录",
    remember: "记住登录信息",
    switchPC: "切换至后台",
    setupPassword: "设置密码",
    newPassword: "新密码",
    newPasswordPlaceholder: "请输入新密码",
    retypePassword: "再次输入新密码",
    retypePasswordPlaceholder: "请再次输入新密码",
    setupPasswordRule: "密码为6-32字符，需包含数字、字母、符号等两种以上组合。",
    setupPasswordRuleRefer: "密码为6-32字符，需包含数字、字母、符号等两种以上组合。请重新输入。",
    setupPasswordOk: "确定",
    setupPasswordDiffer: "两次输入的密码不一致，请重新输入。",
    err_def: "登录失败，请刷新页面后重试。",
    err_2000: "验证码输入错误。",
    err_2010: "登录信息输入错误。",
    err_4001: "该账号未授权任何功能。",
    err_4011: "门店已停用，无法登录。",
    err_4012: "加盟商已停用，无法登录。",
    err_4013: "品牌已停用，无法登录。",
    err_4014: "集团已停用，无法登录。",
    err_4015: "区域已停用，无法登录。",
    err_5001: "设置密码失败，请重试。",
  },
}
