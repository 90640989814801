export default {
  app: {
    orgCode: "Group/Brand Number",
    orgCodePlaceholder: "Please enter the group/brand number",
    username: "Account",
    usernamePlaceholder: "Please enter an account",
    password: "Password",
    passwordPlaceholder: "Please enter the password",
    captcha: "Verification Code",
    captchaPlaceholder: "Please enter the verification code",
    captchaExpired: "Please refresh the verification code",
    login: "Login",
    remember: "Remember login information",
    switchPC: "Switch to backend",
    setupPassword: "Set password",
    newPassword: "New Password",
    newPasswordPlaceholder: "Please enter a new password",
    retypePassword: "Enter the new password again",
    retypePasswordPlaceholder: "Please enter the new password again.",
    setupPasswordRule: "Password must be 6 to 32 characters and contain at least two of the following: numbers, letters, and symbols.",
    setupPasswordRuleRefer: "Password must be 6 to 32 characters and contain at least two of the following: numbers, letters, and symbols. Please try again.",
    setupPasswordOk: "Confirm",
    setupPasswordDiffer: "The passwords entered do not match. Please try again.",
    err_def: "Login failed. Please refresh the page and try again.",
    err_2000: "Verification code error.",
    err_2010: "Login information error.",
    err_4001: "This account has not authorized any functions.",
    err_4011: "The store has been deactivated, unable to log in.",
    err_4012: "The franchisee has been deactivated, unable to log in.",
    err_4013: "Unable to log in. The brand has been disabled.",
    err_4014: "Unable to log in. The group has been disabled.",
    err_4015: "The region has been deactivated and login is not possible.",
    err_5001: "Password setup failed. Please try again.",
  },
}