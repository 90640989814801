<template>
    <div class="div-home">
        <div class="div-title">
            <div class="left">
                <div style="font-size: 18px" v-if="scene === 1">
                    {{ $t("app.login") }}
                </div>
            </div>
            <div class="right">
                <img :src="require('@/assets/image/country.webp')" alt="" />
                <select
                    :class="
                        scene === 1 ? 'lang-select-mobile' : 'lang-select-pc'
                    "
                    v-model="selectedLang"
                    @change="changeLanguage"
                >
                    <option
                        :value="language.lang"
                        v-for="language in languageArray"
                        :key="language.lang"
                    >
                        {{ language.name }}
                    </option>
                </select>
                <svg
                    viewBox="0 0 1000 1000"
                    preserveAspectRatio="xMidYMid meet"
                    focusable="false"
                    style="
                        pointer-events: none;
                        display: block;
                        width: 16px;
                        height: 16px;
                    "
                >
                    <g>
                        <path
                            d="M399 275c-16-18-15-46 3-63 18-16 46-15 63 4l224 244c15 17 15 43 0 59l-229 250c-16 18-45 20-63 4-18-16-20-45-3-63l203-220-198-215z"
                        ></path>
                    </g>
                </svg>
            </div>
        </div>

        <!-- PC端 -->
        <div
            class="div-center-pc"
            style="width: 840px; height: 460px"
            v-if="scene === 0"
        >
            <img
                :src="require('@/assets/image/bg.webp')"
                alt=""
                style="
                    border-top-left-radius: 16px;
                    border-bottom-left-radius: 16px;
                "
            />
            <login-pane class="login-pane-pc" :mobile="false" />
        </div>
        <div class="div-bottom-H" v-if="scene === 0">
            <span>Powered By Autopos Technology Ltd.</span>
            <div class="div-beian" v-if="beianVisible">
                <span>|</span>
                <img
                    :src="require('@/assets/image/beian.png')"
                    alt=""
                    style="width: 14px; height: 14px"
                />
                <a
                    href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=35020302035535"
                    target="_blank"
                    >闽公网安备 35020302035535号</a
                >
                <span>|</span>
                <a href="https://beian.miit.gov.cn/" target="_blank"
                    >闽ICP备2022019100号-1</a
                >
            </div>
        </div>
        
        <!-- 移动端 -->
        <div class="div-center-mobile" v-if="scene === 1">
            <login-pane class="login-pane-mobile" :mobile="true" />
        </div>
        <div v-if="scene === 1">
            <div style="text-align: center">
                <img
                    :src="require('@/assets/image/logo.webp')"
                    alt=""
                    width="150"
                    height="45"
                />
            </div>
            <div class="div-bottom-V">
                <div style="text-align: center" v-if="beianVisible">
                    <img
                        :src="require('@/assets/image/beian.png')"
                        alt=""
                        style="
                            display: inline-block;
                            vertical-align: middle;
                            width: 8px;
                            height: 8px;
                        "
                    />
                    <a
                        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=35020302035535"
                        target="_blank"
                        style="display: inline-block; vertical-align: middle"
                        >闽公网安备 35020302035535号</a
                    >
                </div>
                <div style="text-align: center" v-if="beianVisible">
                    <a href="https://beian.miit.gov.cn/" target="_blank"
                        >闽ICP备2022019100号-1</a
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import LoginPane from "@/components/LoginPane.vue";
    import { getLanguageArray, getLang } from "@/lang/index";

    export default {
        name: "Home",
        components: {
            LoginPane,
        },
        data() {
            return {
                scene: 0,
                beianVisible: false,
                browserLang: "en",
                selectedLang: "en",
                languageArray: getLanguageArray(),
            };
        },
        methods: {
            // 判断是否是手机端，如果是，返回true
            isMobile() {
                let flag = navigator.userAgent.match(
                    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
                );
                return flag;
            },
            changeLanguage() {
                if (this.selectedLang !== this.$i18n.locale) {
                    this.$i18n.locale = this.selectedLang;
                }
            },
        },
        mounted() {
            // 获取支持的语言
            let tempLang = navigator.language || navigator.userLanguage;
            this.browserLang = getLang(tempLang);
            let queryLocale = this.$route.query["locale"];
            if (queryLocale) {
                this.selectedLang = getLang(queryLocale);
                // 清除地址栏的参数
                this.$router.push({ query: {} });
            } else {
                this.selectedLang = this.browserLang;
            }
            this.$i18n.locale = this.selectedLang;
            if (this.isMobile()) {
                // 移动端
                this.scene = 1;
            } else {
                // PC端
                this.scene = 0;
            }
            this.beianVisible = !window.location.hostname.includes(".cloud");
        },
    };
</script>
<style lang="less" src="./Home.less" scoped />