export default {
  app: {
    orgCode: "Nomor Grup/Merek",
    orgCodePlaceholder: "Silakan masukkan Nomor Grup/Merek",
    username: "Akaun",
    usernamePlaceholder: "Silakan masukkan akun",
    password: "sandi",
    passwordPlaceholder: "Silakan masukkan sandi",
    captcha: "Kode verifikasi",
    captchaPlaceholder: "Silakan masukkan kode verifikasi",
    captchaExpired: "Silakan refresh kode verifikasi",
    login: "Login",
    remember: "Ingat informasi daftar masuk",
    switchPC: "Beralih ke bagian belakang",
    setupPassword: "Mengatur kata sandi",
    newPassword: "kata sandi baru",
    newPasswordPlaceholder: "Silakan masukkan kata sandi baru",
    retypePassword: "Masukkan kata sandi baru lagi",
    retypePasswordPlaceholder: "Silakan masukkan kata sandi baru lagi",
    setupPasswordRule: "Kata sandi harus terdiri dari 6-32 karakter dan mengandung lebih dari dua kombinasi angka, huruf, dan simbol.",
    setupPasswordRuleRefer: "Kata sandi harus terdiri dari 6-32 karakter dan mengandung lebih dari dua kombinasi angka, huruf, dan simbol. Silakan masukkan kembali.",
    setupPasswordOk: "Tentu",
    setupPasswordDiffer: "Kata sandi yang dimasukkan dua kali tidak konsisten, silakan masukkan kembali.",
    err_def: "Gagal masuk, silakan segarkan halaman dan coba lagi.",
    err_2000: "Galat input kode verifikasi.",
    err_2010: "Galat input informasi log masuk.",
    err_4001: "Akun ini tidak diotorisasi untuk fungsi apa pun.",
    err_4011: "Toko telah dinonaktifkan, tidak dapat masuk.",
    err_4012: "Mitra waralaba telah dinonaktifkan, tidak dapat masuk.",
    err_4013: "Merek telah dinonaktifkan, tidak dapat masuk.",
    err_4014: "Grup telah dinonaktifkan, tidak dapat masuk.",
    err_4015: "Daerah telah dinonaktifkan dan daftar masuk tidak mungkin.",
    err_5001: "Penetapan kata sandi gagal, silakan coba lagi.",
  },
}