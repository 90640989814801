export default {
  app: {
    orgCode: "集團/品牌編號",
    orgCodePlaceholder: "請輸入集團/品牌編號",
    username: "帳號",
    usernamePlaceholder: "請輸入帳號",
    password: "密碼",
    passwordPlaceholder: "請輸入密碼",
    captcha: "驗證碼",
    captchaPlaceholder: "請輸入驗證碼",
    captchaExpired: "請刷新驗證碼",
    login: "登入",
    remember: "記住登入資訊",
    switchPC: "切換至後台",
    setupPassword: "設定密碼",
    newPassword: "新密碼",
    newPasswordPlaceholder: "請輸入新密碼",
    retypePassword: "再次輸入新密碼",
    retypePasswordPlaceholder: "請再次輸入新密碼",
    setupPasswordRule: "密碼為6 - 32字符，需包含數字、字母、符號等兩種以上組合。",
    setupPasswordRuleRefer: "密碼為6 - 32字符，需包含數字、字母、符號等兩種以上組合。請重新輸入。",
    setupPasswordOk: "確定",
    setupPasswordDiffer: "兩次輸入的密碼不一致，請重新輸入。",
    err_def: "登入失敗。請刷新頁面後重試。",
    err_2000: "驗證碼錯誤。",
    err_2010: "登入資訊錯誤。",
    err_4001: "該帳號未授權任何功能。",
    err_4011: "門店已停用，無法登錄。",
    err_4012: "加盟商已停用，無法登錄。",
    err_4013: "品牌已停用，無法登入。",
    err_4014: "集團已停用，無法登入。",
    err_4015: "區域已停用，無法登入。",
    err_5001: "設定密碼失敗，請重試。",
  },
}