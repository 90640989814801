export default {
  app: {
    orgCode: "그룹/브랜드 번호",
    orgCodePlaceholder: "그룹/브랜드 번호를 입력하세요",
    username: "계정",
    usernamePlaceholder: "계정을 입력하세요",
    password: "비밀번호",
    passwordPlaceholder: "비밀번호를 입력해주세요",
    captcha: "확인 코드",
    captchaPlaceholder: "인증 코드를 입력 해주세요",
    captchaExpired: "인증 코드를 새로 고침하십시오",
    login: "로그인",
    remember: "로그인 정보 기억하기",
    switchPC: "백엔드로 전환",
    setupPassword: "암호를 설정하세요",
    newPassword: "새 비밀번호",
    newPasswordPlaceholder: "새로운 비밀번호를 입력해주세요",
    retypePassword: "새 비밀번호를 다시 입력하세요.",
    retypePasswordPlaceholder: "새 비밀번호를 다시 입력해주세요",
    setupPasswordRule: "비밀번호는 6~32자여야 하며 숫자, 문자, 기호의 조합을 3개 이상 포함해야 합니다.",
    setupPasswordRuleRefer: "비밀번호는 6~32자 이내로 숫자, 문자, 기호를 2개 이상 조합하여 입력해야 합니다. 다시 입력해 주세요.",
    setupPasswordOk: "확신하는",
    setupPasswordDiffer: "두 번 입력한 비밀번호가 일치하지 않습니다. 다시 입력해 주세요.",
    err_def: "로그인 실패, 페이지를 새로고침하고 다시 시도하십시오.",
    err_2000: "인증코드 입력 오류입니다.",
    err_2010: "로그인 정보 입력 오류입니다.",
    err_4001: "이 계정은 어떤 기능에도 승인되지 않습니다.",
    err_4011: "가게 사용이 중단되었습니다. 로그인할 수 없습니다.",
    err_4012: "프랜차이즈 파트너가 중단되었습니다. 로그인할 수 없습니다.",
    err_4013: "브랜드가 비활성화되었습니다, 로그인할 수 없습니다.",
    err_4014: "그룹이 비활성화되었습니다, 로그인할 수 없습니다.",
    err_4015: "영역이 비활성화되어 로그인할 수 없습니다.",
    err_5001: "비밀번호 설정에 실패했습니다. 다시 시도하십시오.",
  },
}