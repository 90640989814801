export default {
  app: {
    orgCode: 'แปลเป็นภาษาไทยคือ "รหัสกลุ่ม/แบรนด์',
    orgCodePlaceholder: "แปลเป็นภาษาไทยคือ",
    username: "บัญชี",
    usernamePlaceholder: "กรุณาใส่ชื่อผู้ใช้",
    password: "รหัสผ่าน",
    passwordPlaceholder: "กรุณาใส่รหัสผ่าน",
    captcha: "รหัสยืนยัน",
    captchaPlaceholder: "กรุณากรอกรหัสยืนยัน",
    captchaExpired: "กรุณารีเฟรชรหัสยืนยัน",
    login: "เข้าสู่ระบบ",
    remember: "จดจำข้อมูลการเข้าสู่ระบบ",
    switchPC: "เปลี่ยนไปที่แบ็กเอนด์",
    setupPassword: "ตั้งรหัสผ่าน",
    newPassword: "รหัสผ่านใหม่",
    newPasswordPlaceholder: "กรุณากรอกรหัสผ่านใหม่",
    retypePassword: "ป้อนรหัสผ่านใหม่อีกครั้ง",
    retypePasswordPlaceholder: "กรุณากรอกรหัสผ่านใหม่อีกครั้ง",
    setupPasswordRule: "รหัสผ่านจะต้องมีอักขระ 6-32 ตัวและมีตัวเลข ตัวอักษร และสัญลักษณ์รวมกันมากกว่าสองชุด",
    setupPasswordRuleRefer: "รหัสผ่านจะต้องมีอักขระ 6-32 ตัวและมีตัวเลข ตัวอักษร และสัญลักษณ์รวมกันมากกว่า 2 ชุด กรุณาป้อนใหม่",
    setupPasswordOk: "แน่นอน",
    setupPasswordDiffer: "รหัสผ่านที่ป้อนสองครั้งไม่สอดคล้องกัน โปรดป้อนใหม่",
    err_def: "การเข้าสู่ระบบล้มเหลว กรุณารีเฟรชหน้าและลองอีกครั้ง",
    err_2000: "รหัสยืนยันไม่ถูกต้อง",
    err_2010: "ป้อนข้อมูลเข้าสู่ระบบไม่ถูกต้อง",
    err_4001: "บัญชีนี้ไม่ได้รับอนุญาตสำหรับการทำงานใดๆ",
    err_4011: "ร้านค้าถูกปิดใช้งานแล้ว ไม่สามารถเข้าสู่ระบบได้",
    err_4012: "ตัวแทนฟรานไชส์ถูกปิดใช้งานแล้ว ไม่สามารถเข้าสู่ระบบได้",
    err_4013: "แบรนด์ถูกปิดใช้งาน ไม่สามารถเข้าสู่ระบบได้",
    err_4014: "กลุ่มถูกปิดใช้งาน ไม่สามารถเข้าสู่ระบบได้",
    err_4015: "พื้นที่ถูกปิดใช้งานและไม่สามารถเข้าสู่ระบบได้",
    err_5001: "การตั้งค่ารหัสผ่านล้มเหลว โปรดลองอีกครั้ง",
  },
}