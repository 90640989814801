export default {
  app: {
    orgCode: "Номер группы/торговой марки",
    orgCodePlaceholder: "Пожалуйста, введите номер группы/бренда",
    username: "счет",
    usernamePlaceholder: "Пожалуйста, введите номер счета",
    password: "пароль",
    passwordPlaceholder: "Пожалуйста, введите пароль",
    captcha: "Проверочный код",
    captchaPlaceholder: "Пожалуйста, введите проверочный код",
    captchaExpired: "Пожалуйста, обновите код подтверждения.",
    login: "Авторизоваться",
    remember: "Запомнить данные для входа",
    switchPC: "Переключиться на фон",
    setupPassword: "Установить пароль",
    newPassword: "Новый пароль",
    newPasswordPlaceholder: "Пожалуйста, введите новый пароль",
    retypePassword: "Введите новый пароль еще раз",
    retypePasswordPlaceholder: "Пожалуйста, введите новый пароль еще раз",
    setupPasswordRule: "Пароль состоит из 6–32 символов и должен содержать две и более комбинации цифр, букв, символов и т. д.",
    setupPasswordRuleRefer: "Пароль состоит из 6–32 символов и должен содержать две и более комбинации цифр, букв, символов и т. д. Пожалуйста, войдите еще раз.",
    setupPasswordOk: "Конечно",
    setupPasswordDiffer: "Пароли, введенные дважды, не совпадают, пожалуйста, введите еще раз.",
    err_def: "Не удалось войти, обновите страницу и повторите попытку.",
    err_2000: "Код подтверждения был введен неверно.",
    err_2010: "Данные для входа введены неверно.",
    err_4001: "Эта учетная запись не авторизована для каких-либо функций.",
    err_4011: "Магазин деактивирован и войти в систему невозможно.",
    err_4012: "Франчайзи деактивирован и не может войти в систему.",
    err_4013: "Бренд деактивирован и войти в систему невозможно.",
    err_4014: "Группа деактивирована и войти в нее невозможно.",
    err_4015: "Область отключена и войти в систему невозможно.",
    err_5001: "Не удалось установить пароль, попробуйте еще раз.",
  },
}