export default {
  app: {
    orgCode: "Numero ng Grupo/Tatak",
    orgCodePlaceholder: "Mangyaring maglagay ng numero ng grupo/tatak",
    username: "Username",
    usernamePlaceholder: "Mangyaring ipasok ang Username",
    password: "Password",
    passwordPlaceholder: "pakilagay ang Password",
    captcha: "Verification Code",
    captchaPlaceholder: "pakilagay ang Verification Code",
    captchaExpired: "Mangyaring i-refresh ang verification code",
    login: "Mag log in",
    remember: "Tandaan ang impormasyon sa login",
    switchPC: "Lipat sa likod",
    setupPassword: "Itakda ang password",
    newPassword: "Bagong Password",
    newPasswordPlaceholder: "Mangyaring magpasok ng bagong password",
    retypePassword: "Ipasok muli ang bagong password",
    retypePasswordPlaceholder: "Pakipasok muli ang bagong password",
    setupPasswordRule: "Ang password ay dapat na 6-32 character at naglalaman ng higit sa dalawang kumbinasyon ng mga numero, titik, at simbolo.",
    setupPasswordRuleRefer: "Ang password ay kailangang 6-32 character at naglalaman ng higit sa dalawang kumbinasyon ng mga numero, titik, at simbolo. Pakipasok muli.",
    setupPasswordOk: "Oo naman",
    setupPasswordDiffer: "Ang mga password na ipinasok ng dalawang beses ay hindi pare-pareho, mangyaring muling ipasok.",
    err_def: "Nabigo ang pag-login, pakirefresh ang pahina at subukang muli.",
    err_2000: "maling verification code.",
    err_2010: "Ang impormasyon sa pag-login ay naipasok nang hindi tama.",
    err_4001: "Ang account na ito ay hindi awtorisado para sa anumang mga function.",
    err_4011: "Ang tindahan ay hindi na ginagamit, hindi makapag-login.",
    err_4012: "Ang prangkisahang negosyo ay hindi na ginagamit, hindi makapag-login.",
    err_4013: "Ang brand ay hindi pinagana, hindi makapag-login.",
    err_4014: "Ang grupo ay hindi pinagana, hindi makapag-login.",
    err_4015: "Ang región ay deaktibatin at hindi maaari ang login.",
    err_5001: "Ang pagtatakda ng password ay nabigo, subukan muli.",
  },
}