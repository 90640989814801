export default {
  app: {
    orgCode: "Guruh/brend raqami",
    orgCodePlaceholder: "Iltimos, guruh/brend raqamini kiriting",
    username: "hisob",
    usernamePlaceholder: "Iltimos, hisob raqamini kiriting",
    password: "parol",
    passwordPlaceholder: "Iltimos, parolni kiriting",
    captcha: "Tasdiq kodi",
    captchaPlaceholder: "Tasdiqlash kodini kiriting",
    captchaExpired: "Tasdiqlash kodini yangilang.",
    login: "Tizimga kirish",
    remember: "Kirish ma'lumotlarini eslab qoling",
    switchPC: "Orqa fonga o'tish",
    setupPassword: "Parolni o'rnating",
    newPassword: "Yangi parol",
    newPasswordPlaceholder: "Iltimos, yangi parolni kiriting",
    retypePassword: "Yangi parolni yana kiriting",
    retypePasswordPlaceholder: "Iltimos, yangi parolni qayta kiriting",
    setupPasswordRule: "Parol 6-32 belgidan iborat bo'lib, ikki yoki undan ortiq raqamlar, harflar, belgilar va boshqalar kombinatsiyasidan iborat bo'lishi kerak.",
    setupPasswordRuleRefer: "Parol 6-32 belgidan iborat bo'lib, ikki yoki undan ortiq raqamlar, harflar, belgilar va boshqalar kombinatsiyasidan iborat bo'lishi kerak. Iltimos, qayta kiriting.",
    setupPasswordOk: "Albatta",
    setupPasswordDiffer: "Ikki marta kiritilgan parollar mos emas, qayta kiriting.",
    err_def: "Kirish amalga oshmadi, sahifani yangilang va qaytadan urinib ko'ring.",
    err_2000: "Tasdiqlash kodi noto'g'ri kiritilgan.",
    err_2010: "Kirish ma'lumotlari noto'g'ri kiritilgan.",
    err_4001: "Bu hisob hech qanday funksiyalarga ruxsat berilmagan.",
    err_4011: "Do'kon o'chirilgan va tizimga kirib bo'lmaydi.",
    err_4012: "Franchayzi o'chirilgan va tizimga kirib bo'lmaydi.",
    err_4013: "Brend o'chirilgan va tizimga kirib bo'lmaydi.",
    err_4014: "Guruh o'chirilgan va tizimga kirib bo'lmaydi.",
    err_4015: "Hudud o'chirilgan va tizimga kirib bo'lmaydi.",
    err_5001: "Parolni o‘rnatib bo‘lmadi, qayta urinib ko‘ring.",
  },
}